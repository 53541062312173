import React, { Component,useState,useEffect,useRef } from "react";
import ServiceOne from "../../elements/service/ServiceOne";
import bgImage from "../../../public/assets/images/featured/Header.png"
import { Parallax } from "react-parallax";
import "../common/customStyles.css"

class SliderOne extends Component {
  render() {
    return (
        
        <div className="slider-activation">

        {/* Start Single Slide */}
        <Parallax
          bgImage={"/assets/images/featured/Header.png"}
          strength={600}
          renderLayer={percentage => (
            <div
                style={{
                    position: 'absolute',
                    background: `linear-gradient(to right, rgba(0, 0, 0, 0.8) ${50 + (50 * percentage)}%, rgba(0, 0, 0, 0) 100%)`,
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                }}
            />
        )}
        >
          <div
            className="slide slide-style-1 slider-fixed--height d-flex align-items-center"
            data-black-overlay="6"
          >
            <div className="container position-relative">
              <FadeInSection>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="inner">
                      <img
                        className="TexelLogo"
                        src="/assets/images/logo/mainlogo.png"
                        alt="TexelBitLogo"
                      />
                    </div>
                  </div>
                </div>
              </FadeInSection>
              {/* Start Service Area */}
              <div className="service-wrapper service-white">
                <FadeInSection>
                  <ServiceOne />
                </FadeInSection>
              </div>
              {/* End Service Area */}
            </div>
          </div>
        </Parallax>
        {/* End Single Slide */}
      </div>
    );
  }
}

// Intersection Observer Hook to fade in sections.
const FadeInSection = (props) => {
    const [isVisible, setVisible] = useState(true); // Default to true to render on server side
    const domRef = useRef();
  
    useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
      return () => observer.unobserve(domRef.current);
    }, []);
  
    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  };
  

export default SliderOne;
import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Desarrollo de Videojuegos',
        description: 'Creación de mundos interactivos cautivadores con jugabilidad emocionante y gráficos deslumbrantes.'
    },
    {
        icon: '02',
        title: 'Modelado 3D',
        description: 'Transformamos tus ideas en modelos 3D realistas y detallados para presentaciones, marketing y más.'
    },
    {
        icon: '03',
        title: 'Desarrollo de Aplicaciones',
        description: 'Diseñamos aplicaciones intuitivas y potentes que satisfacen necesidades y superan expectativas'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="TexelIcons">
                                    <img style={{maxHeight:100}} src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;
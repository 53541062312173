import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'Conocenos',
        description = '¡Conócenos!';
        return(
            
            <React.Fragment>
               <div className="about-wrapper">
    <div className="container">
        <div className="row row--35 align-items-center">

            <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                    <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/>
                </div>
            </div>

            <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                    <div className="section-title">
                        <h2 className="title">{title}</h2>
                        {/* <p className="description">{description}</p> */}
                        <p className="description2">En Texel Bit, fusionamos lo cool de la creatividad con la magia de la tecnología, dando vida a experiencias únicas en videojuegos, web y apps. Nos la jugamos por soluciones que marcan la diferencia, ¡hechas a tu medida y con una calidad que no tiene límites!</p>
                    </div>
                </div>
            </div>

           
        </div>
    </div>
</div>


            </React.Fragment>
        )
    }
}
export default About;
import React, { Component } from "react";
import { GiConsoleController  } from 'react-icons/gi';
import { ImMobile  } from 'react-icons/im';
import { SiNintendogamecube  } from 'react-icons/si';
import { GoBrowser  } from 'react-icons/go';

const ServiceList = [
    {
        icon: <GiConsoleController />,
        title: 'Desarrollo de Videojuegos',
        description: 'Sumérgete en universos vívidos. Texel Bit crea videojuegos con tramas emocionantes, gráficos impactantes y jugabilidad adictiva. La aventura de tus sueños, a un click. 🎮'
    },
    {
        icon: <SiNintendogamecube />,
        title: 'Modelado 3D',
        description: 'Dale vida a tus ideas. En Texel Bit, modelamos detalles en 3D con precisión asombrosa para proyectos, marketing y entretenimiento. Realismo y creatividad en 3D. 🌟'
    },
    {
        icon: <GoBrowser />,
        title: 'Desarrollo Web',
        description: 'Convierte visitantes en fans. Texel Bit diseña sitios web que capturan, informan y fascinan. Un hogar digital atractivo, funcional y memorable te espera. 🌐'
    },
    {
        icon: <ImMobile />,
        title: 'Desarrollo de Aplicaciones',
        description: 'Innovación en tu bolsillo. Texel Bit desarrolla aplicaciones intuitivas y potentes. Conecta, organiza y sorprende con tecnología móvil sobresaliente.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Servicios',
        description = 'En Texel Bit, fusionamos creatividad y tecnología para forjar videojuegos, sitios web, modelado 3D y apps que asombran. Innovación y excelencia, en tu palma. 🚀';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            
                            {
    /* 
    <div className="service-btn">
        <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a>
    </div>
    */
}
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;

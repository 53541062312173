import React, { Component } from "react";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <h2>¡Vive lo inexplorado<br /> ya!</h2>
                                        <a className="rn-button-style--2" href="https://wa.me/573175881177" target="_blank" rel="noopener noreferrer">
                                            <span>Contáctanos</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Contact Details Widget */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Contacto</h4>
                                                <ul className="ft-link">
                                                    <li><a href="https://wa.me/573175881177" target="_blank" rel="noopener noreferrer">+57 317 588 1177</a></li>
                                                    <li><a href="mailto:gerencia@texelbit.com">gerencia@texelbit.com</a></li>
                                                    <li><a>Calle 35 #17-77 Bucaramanga, Santander</a></li>
                                                    <li><a>Operating Hours: 9am - 5pm</a></li>
                                                </ul>
                                            </div>
                                        </div>

                                        {/* Quick Links Widget */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Enlaces Rápidos</h4>
                                                <ul className="ft-link">
                                                <li><a href="#about">Acerca de Nosotros</a></li>
<li><a href="#services">Servicios</a></li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2023 Texel Bit</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;
